.blog-content h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  
  .blog-content h2 {
    font-size: 1.75rem;
    font-weight: bold;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  .blog-content h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
  }
  
  .blog-content p {
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  
  .blog-content ol, .blog-content ul {
    margin-bottom: 1rem;
    padding-left: 2rem;
  }

  .blog-content ul {
    list-style-type: disc;
  }
  .blog-content ol {
    list-style-type: decimal;
  }
  
  .blog-content li {
    margin-bottom: 0.5rem;
  }
  
  .blog-content a {
    color: #E07A5F;
    text-decoration: underline;
  }
    
  .blog-content a:hover {
      color: #F4A261;
  }

  .blog-content hr {
      margin: 2rem 0;
      border: none;
      border-top: 1px solid #e1e1e1;
  }
    
  /* Dark mode styles */
  .dark .blog-content a {
      color: #F4A261;
  }
    
  .dark .blog-content a:hover {
      color: #FAF3E9;
  }